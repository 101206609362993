import React from "react"

import Layout from "../components/layout"

const PrivacyPage = () => (

    <Layout>

        <div className="container">
            <h1>Privacy</h1>
            <p>Privacy...</p>
        </div>

    </Layout>
)

export default PrivacyPage